.info {
    background-color: #888d;
    position: absolute;
     margin: auto;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     width: 70%;
     height: 100px;
     padding: 30px;
     border-radius: 20px;
     z-index: 1000;
     text-align: center;
     box-shadow: #333 5px 5px 20px;
}