.form {
  display: flex;
  border-top: 2px solid #D3D3D3;
  gap: 4px;
  margin: 0 4px 4px 4px;
}

.dices {
  display: flex;
  gap: 4px;
  margin: 0 4px 4px 4px;
}
.dices button {
  color: white;
  font-size:x-large;
  background: #666;
  padding: 25px 8px;
  width:25%;
  border: none;
  border-radius: 10px;
  font-family: 'Times New Roman', Times, serif;
}
.input {
  border: none;
  border-radius: 10px;
  padding: 2%;
  width: 80%;
  font-size: 1.2em;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #444;
  padding: 20px;
  display: inline-block;
  border: none;
  width: 25%;
  border-radius: 10px;
}