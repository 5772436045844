.messageBox {
  background: #F3F3F3;
  border-radius: 10px;
  padding: 8px 12px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 8px;
  gap: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #600;
  letter-spacing: 0.3px;
  font-style: oblique;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #474;
}

.backgroundLight {
  background: #ded;
}

.dice {
  display: inline-block;
  text-align: center;
  color: black;
  background-color: #fff;
  padding-top: 7px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px;
  margin: 1px;
  height:2em;
  width: 2em;
  font-family: 'Times New Roman', Times, serif;
}
.detail {
  display: inline-block;
  text-align: center;
  color: black;
  padding: 7px;
  margin: 2px;
  height:2em;
  letter-spacing: 4px;
  font-family: 'Times New Roman', Times, serif;
}
.result {
  display: block;
  font-weight: bold;
  font-size: 200%;
  font-family: 'Times New Roman', Times, serif;
}